import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '@/components/Layout';
import { Sidebar } from '@/components/Sidebar';
import { Page } from '@/components/Page';
import { Projects } from '@/components/Projects';
import { useSiteMetadata } from '@/hooks';
import { Node } from "@/types";

import { Meta } from "@/components/Meta";

type Props = {
  data: {
    markdownRemark: Node,
    github: any,
  }
};

export const Head: React.FC<Props> = ({ data }) => {
  const { title, subtitle, url } = useSiteMetadata();

  const {
    frontmatter: {
      title: postTitle,
      description: postDescription = "",
      socialImage,
    },
  } = data.markdownRemark;

  const description = postDescription || subtitle;
  const image = socialImage?.publicURL && url.concat(socialImage?.publicURL);

  return (
    <Meta
      title={`${postTitle} - ${title}`}
      description={description}
      image={image}
    />
  );
};

const ProjectsTemplate: React.FC<Props> = ({ data }: Props) => {
  const { author } = useSiteMetadata();
  const { html: pageBody } = data.markdownRemark;
  const { frontmatter } = data.markdownRemark;
  const {
    viewer: {
      repositories: { edges },
    },
  } = data.github as any;
  const { title: pageTitle } = frontmatter;

  const projects = edges
    .filter((edge: any) => author.projects.owners.includes(edge.node.owner.login))
    .slice(0, author.projects.count);

  return (
    <Layout>
      <Sidebar />
      <Page title={pageTitle}>
        <div dangerouslySetInnerHTML={{ __html: pageBody }} />
        <Projects edges={projects} />
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query ProjectsPage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
        socialImage {
          publicURL
        }
      }
    }
    github {
      viewer {
        repositories(ownerAffiliations: [OWNER, ORGANIZATION_MEMBER], privacy: PUBLIC, isFork: false, isLocked: false, first: 20, orderBy: { field: STARGAZERS, direction: DESC }) {
          edges {
            node {
              id
              name
              url
              owner {
                login
              }
              description
              stargazers {
                totalCount
              }
              forkCount
              languages(first: 3) {
                nodes {
                  id,
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { ProjectsTemplate };
export default ProjectsTemplate;
