import React from 'react';
import { Card, TitleWrap } from './Card';
import Star from './Icons/Star';
import Fork from './Icons/Fork';
import {
  Grid, Item, Content, Stats, Languages,
} from './styles';

interface Props {
  edges: {
    node: {
      id: string,
      name: string,
      description: string,
      url: string,
      stargazers: {
        totalCount: number,
      },
      forkCount: number,
      languages: {
        nodes: {
          id: string,
          name: string,
        }[]
      },
    }
  }[],
}

// eslint-disable-next-line import/prefer-default-export
const Projects = ({ edges }: Props) => (
  <Grid>
    {edges.map(({ node }) => (
      <Item key={node.id} as="a" href={node.url} target="_blank" rel="noopener noreferrer">
        <Card>
          <Content>
            <h4>{node.name}</h4>
            <p>{node.description}</p>
          </Content>
          <TitleWrap>
            <Stats>
              <div>
                <Star color='#000' />
                <span>{node.stargazers.totalCount}</span>
              </div>
              <div>
                <Fork color='#000' />
                <span>{node.forkCount}</span>
              </div>
            </Stats>
            <Stats>
              <Languages>
                {
                  node.languages.nodes.map(({
                                              id,
                                              name,
                                            }) => (
                    <span key={id}>
                          {name}
                        </span>
                  ))
                }
              </Languages>
            </Stats>
          </TitleWrap>
        </Card>
      </Item>
    ))}
  </Grid>
);

export { Projects };
export default Projects;
